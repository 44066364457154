import React from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
// import SmallBanner from "@components/common/SmallBanner";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";
import { Link } from "gatsby";
import Image from "@components/common/CloudinaryImage";

const Component = () => {
    return (
        <>
            <Layout>
                <Seo title="Nuestra tienda" description="" />

                <div className="container lg:px-0 py-20">
                    <div className="grid grid-cols-1 gap-10 text-gray-700">
                        {data
                            .filter(x => x.enabled)
                            .map(x => (
                                <RenderItem key={x.id} data={x} />
                            ))}
                    </div>
                </div>
            </Layout>
        </>
    );
};

const RenderItem = ({ data }) => {
    const [hover, setHover] = React.useState(false);

    return (
        <Link href={data.link}>
            <div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                role="button"
                tabIndex={data.id}
                className={`h-96 xl:h-116 rounded-xl duration-300 relative w-full ${
                    hover ? "shadow-lg" : "shadow"
                }`}
            >
                <Image
                    filename={data.image}
                    alt={data.title}
                    className={"w-full absolute top-0 left-0 h-full z-0 object-cover rounded-xl"}
                />

                <div
                    className={`bg-black duration-300 absolute z-10 h-full w-full top-0 left-0 rounded-xl ${
                        hover ? "bg-opacity-25 opacity-25" : "bg-opacity-50 opacity-50"
                    }}`}
                />

                <div className="absolute z-20 bottom-0 left-0 w-full space-y-5 p-10">
                    <h1 className="font-montserrat font-semibold text-2xl lg:text-4xl text-white">
                        {data.title}
                    </h1>
                    <h2 className="font-montserrat text-white text-sm lg:text-lg bg-black bg-opacity-75 px-5 py-2 rounded-xl hidden md:block">
                        {data.description}
                    </h2>
                </div>
            </div>
        </Link>
    );
};

const data = [
    {
        id: 0,
        title: "Aplicaciones para dispositivos móviles",
        description:
            "iGuB para Guàrdia Urbana y policías locales. iMossos para Mossos d'Esquadra. Práctica con miles de preguntas, test por temas y a medida. Aprende muy rápido y en muy poco tiempo. Cuando y donde quieras desde tu móvil o tablet.",
        image: "bgs/bgIndex",
        link: "/tienda/apps",
        enabled: true,
    },
    {
        id: 1,
        title: "Libros",
        description:
            "Aquí tienes el material de estudio más completo y actualizado para sacar la máxima nota en los exámenes y conseguir tu plaza.",
        image: "bgs/bgBooks4",
        link: "/tienda/libros",
        enabled: true,
    },
    {
        id: 2,
        title: "Cursos online",
        description:
            "La mejor preparación desde cualquier lugar y a la hora que necesites. Aprovecha nuestra metodología interactiva, materiales actualizados y el apoyo de expertos para alcanzar tu plaza. Aprende más rápido en menos tiempo.",
        image: "bgs/bgCursosOnline",
        link: "/tienda/cursos-online",
        enabled: true,
    },
    {
        id: 3,
        title: "Cursos presenciales",
        description:
            "Potencia tu preparación. Clases impartidas por compañeros con muchísima experiencia, resuelve todas tus dudas y motívate al máximo. No dejes pasar esta oportunidad de alcanzar tu sueño. ¡Inscríbete ahora y asegura tu éxito en la opo!",
        image: "bgs/bgCourses",
        link: "/tienda/cursos-presenciales",
        enabled: true,
    },
    {
        id: 4,
        title: "Pruebas físicas",
        description:
            "Nuestros entrenadores expertos te guiarán en el desarrollo de resistencia, fuerza y agilidad, adaptando los ejercicios a tus necesidades individuales. Prepárate de forma efectiva y asegura tu rendimiento físico para alcanzar tu objetivo.",
        image: "bgs/bgSports",
        link: "/tienda/fisicas",
        enabled: true,
    },
    {
        id: 5,
        title: "Psicotécnicos",
        description:
            "Domina los test con nuestra preparación especializada. Nuestros expertos te proporcionarán las herramientas y estrategias necesarias para superar con éxito estas pruebas, mejorando tu agilidad mental, razonamiento lógico y capacidad de concentración.",
        image: "bgs/bgPsicos",
        link: "/tienda/psicotecnicos",
        enabled: true,
    },
    {
        id: 6,
        title: "Certificados",
        description:
            "Obtén tu certificado médico oficial para las pruebas físicas de forma rápida y económica. También puedes hacer un simulacro de pruebas médicas para conocer exactamente si tienes algún punto débil.",
        image: "bgs/bgCertificados",
        link: "/tienda/certificados",
        enabled: true,
    },
    {
        id: 7,
        title: "Entrevistas",
        description:
            "Destaca en la entrevista con nuestra preparación especializada. Nuestros expertos te brindarán las técnicas y consejos necesarios para enfrentarte con la máxima confianza. Aprende a comunicar tus habilidades, experiencia y motivación de manera efectiva asegurando tu éxito.",
        image: "bgs/bgEntrevistas",
        link: "/tienda/entrevistas",
        enabled: true,
    },
    {
        id: 9,
        title: "Audios / PódCasts",
        description:
            "Aprovecha cualquier momento o desplazamiento para seguir estudiando todo el temario directo a tu cerebro de forma amena y divertida.",
        image: "bgs/bgAudios",
        link: "/tienda/audios",
        enabled: true,
    },
    {
        id: 8,
        title: "Otros",
        description:
            "Si no tienes el certificado de nivel de catalán este es tu sitio para prepararlo y pasar el examen sin problemas. Apúntate al ACTIC para tener más méritos en las opos y saca el título después de prepararlo con nuestro curso online.",
        image: "bgs/bgPsicos",
        link: "/tienda/otros",
        enabled: true,
    },
];

export default Component;
